import React from 'react';
import { usePermissions } from 'contexts/PermissionsContext';

const PermissionGate = ({
    children,
    permission,
    fallback = null,
    renderOnError = null
}) => {
    const { hasPermission } = usePermissions();

    try {
        if (hasPermission(permission)) {
            return children;
        }
        return fallback;
    } catch (error) {
        console.error('PermissionGate error:', error);
        return renderOnError;
    }
};

export default PermissionGate; 