import { useState, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { NotificationProvider } from 'components/common/Notifications/NotificationContext';
import {
  BrowserRouter as Router, useLocation
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Palette from "themes/Palette";
import { NavBarProvider } from "./components/common/NavBar/NavBarContext";
import { AuthProvider } from './contexts/AuthContext';
import './fonts.css';
import ScrollToTop from "./utils/ScrollToTop";
import UnderMaintenance from "./components/common/UnderMaintenance";
import { isApiDown } from "./services/api";
import { MainRoutes, AuthRoutes } from "./routes/AppRoutes";
import { Fab } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SuggestionModal from 'components/common/SuggestionModal';
import { TimelineProvider } from './pages/Dashboard/TimelineContext';
import { PermissionsProvider } from './contexts/PermissionsContext';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
      cacheTime: 10 * 60 * 1000, // Keep unused data in cache for 10 minutes
    },
  },
});

function AppContent() {
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);
  const location = useLocation();
  const isAuthPage = ["/", "/forgot-password", "/reset-password"].includes(location.pathname);

  if (isApiDown()) {
    return <UnderMaintenance />;
  }

  return (
    <>
      {!isAuthPage ? <MainRoutes /> : <AuthRoutes />}
      {!isAuthPage && (
        <>
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 100,
            }}
            onClick={() => setIsSuggestionModalOpen(true)}
          >
            <FeedbackIcon />
          </Fab>
          <SuggestionModal
            open={isSuggestionModalOpen}
            onClose={() => setIsSuggestionModalOpen(false)}
          />
        </>
      )}
    </>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <PermissionsProvider>
          <NotificationProvider>
            <Palette>
              <CssBaseline />
              <NavBarProvider>
                <TimelineProvider>
                  <Router>
                    <ScrollToTop />
                    <AppContent />
                  </Router>
                </TimelineProvider>
              </NavBarProvider>
            </Palette>
          </NotificationProvider>
        </PermissionsProvider>
      </AuthProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;


