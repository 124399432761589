import { useMemo } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { PERMISSIONS } from 'contexts/PermissionsContext';

const useNavLinks = () => {
  const links = useMemo(
    () => [
      {
        to: "/guides",
        label: "Guides",
        openInNewTab: false,
        type: 'link',
        permission: PERMISSIONS.VIEW_ALL_LINKS
      },
      {
        to: "/history",
        label: "History",
        openInNewTab: false,
        type: 'link',
        permission: PERMISSIONS.VIEW_ALL_LINKS
      },
      {
        to: "/approvals",
        label: "Approvals",
        openInNewTab: false,
        type: 'link',
        permission: PERMISSIONS.VIEW_APPROVALS

      }
    ],
    []
  );

  return links;
};

export default useNavLinks;
