import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useTheme, Fade, Box, Typography, alpha, Alert,
    Chip, Stack
} from '@mui/material';
import { useDashboardFeed } from 'services/api';
import TimelineSection from './TimelineSection';
import { getDetailComponent } from './detailViews';
import {
    PageContainer, DashboardLayout,
    DetailPane
} from './utils/styles';
import LoadingSpinner from 'utils/LoadingSpinner';
import { createDayItems } from './utils/createItems';
import { useTimelineContext } from './TimelineContext';
import { createLogger } from 'utils/debug-config';

const log = createLogger('Dashboard');

const DAYS_TO_SHOW = [-2, -1, 0, 1, 2];

const LoadingErrorState = ({ isLoading, error, feedError }) => {
    if (!isLoading && !error && !feedError) return null;

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
        }}>
            {isLoading ? <LoadingSpinner /> :
                <Alert severity="error">
                    {feedError?.message || error || 'An error occurred'}
                </Alert>
            }
        </Box>
    );
};

const FeedCounts = ({ counts }) => (
    <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        {Object.entries(counts).map(([feed, count]) => (
            <Chip
                key={feed}
                label={`${feed}: ${count}`}
                color="primary"
                variant="outlined"
            />
        ))}
    </Stack>
);

const groupCounts = (timelineData) => {
    const uniqueItems = new Set();
    return timelineData.reduce((acc, day) => {
        day.items.forEach(item => {
            if (!item.complete) {
                const itemKey = `${item.id}-${item.displayType}`;
                if (!uniqueItems.has(itemKey)) {
                    uniqueItems.add(itemKey);
                    acc[item.displayType] = (acc[item.displayType] || 0) + 1;
                }
            }
        });
        return acc;
    }, {});
};

const Dashboard = () => {
    const theme = useTheme();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [transitioning, setTransitioning] = useState(false);
    const {
        selectedItem,
        isDetailOpen,
        handleItemClick,
        timelineData,
        setTimelineData,
        handleComplete,
        closeDetail
    } = useTimelineContext();
    const [error, setError] = useState(null);

    const { data: feedData, isLoading, error: feedError } = useDashboardFeed();

    const generateDayData = useCallback((date) => {
        const targetDate = date.toISOString().split('T')[0];
        try {
            const allDayItems = createDayItems(feedData, targetDate);
            const items = allDayItems.filter(item =>
                !item.complete
            );
            return { date: new Date(date), items };
        } catch (err) {
            log.error('Error in generateDayData:', err);
            setError(`Error processing timeline data: ${err.message}`);
            return { date: new Date(date), items: [] };
        }
    }, [feedData]);

    const calculatedTimelineData = useMemo(() => {
        if (!feedData) return [];

        return DAYS_TO_SHOW.map(offset => {
            const date = new Date();
            date.setDate(date.getDate() + offset);
            return generateDayData(date);
        });
    }, [feedData, generateDayData]);

    useEffect(() => {
        if (!feedData) return;

        try {
            const newTimelineData = calculatedTimelineData;
            setTimelineData(newTimelineData);
        } catch (err) {
            log.error('Error in timeline data effect:', err);
            setError(`Error updating timeline data: ${err.message}`);
        }
    }, [calculatedTimelineData, setTimelineData]);

    const renderDetailView = useCallback(() => {
        if (!selectedItem) return null;
        try {
            const DetailComponent = getDetailComponent(selectedItem.type);
            return <DetailComponent
                item={selectedItem}
                onClose={closeDetail}
                onComplete={handleComplete}
            />;
        } catch (err) {
            setError('Error rendering detail view');
            return <Alert severity="error">Error loading detail view</Alert>;
        }
    }, [selectedItem, closeDetail, handleComplete]);

    const counts = useMemo(() => {
        if (!timelineData) return {};
        return groupCounts(timelineData);
    }, [timelineData]);

    if (isLoading || feedError || error) {
        return <LoadingErrorState
            isLoading={isLoading}
            error={error}
            feedError={feedError}
        />;
    }

    return (
        <PageContainer>
            <FeedCounts counts={counts} />
            <DashboardLayout>
                <TimelineSection
                    timelineData={timelineData}
                    transitioning={transitioning}
                    onItemClick={handleItemClick}
                    currentDate={currentDate}
                />

                <Fade in={isDetailOpen} timeout={300}>
                    <DetailPane sx={{
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                        opacity: isDetailOpen ? 1 : 0
                    }}>
                        {renderDetailView()}
                    </DetailPane>
                </Fade>
            </DashboardLayout>
        </PageContainer>
    );
};

Dashboard.propTypes = {
    // Add any props if needed
};

export default Dashboard;