import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';

export const ReviewModal = ({ open, handleClose, id, client, account, handleSubmit }) => {
    const [status, setStatus] = useState('');
    const [notes, setNotes] = useState('');

    const onSubmit = () => {
        handleSubmit(status, notes);
        setStatus('');
        setNotes('');
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Update Approval Status</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Client: {client}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Account: {account}
                </Typography>

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        labelId="status-label"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem value="Approved">Approved</MenuItem>
                        <MenuItem value="Do Not Pursue">Do Not Pursue</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    label="Notes"
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={onSubmit} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};