import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "themes/Palette";
import { IconButton, Box } from '@mui/material';

const Logo = ({ clickable = true }) => {
  const { logo } = useTheme();
  const navigate = useNavigate();

  const handleMenu = () => {
    if (clickable) {
      navigate('/search');
    }
  };

  const LogoImage = () => (
    <img
      src={logo}
      alt="Logo"
      style={{
        height: "20px",
        width: "auto",
        display: "block",
      }}
    />
  );

  if (!clickable) {
    return (
      <Box>
        <LogoImage />
      </Box>
    );
  }

  return (
    <IconButton onClick={handleMenu}>
      <LogoImage />
    </IconButton>
  );
};

export default Logo;