import React, { createContext, useContext } from 'react';
import { useAuth } from './AuthContext';

const PermissionsContext = createContext();

export const ROLES = {
    SYS_ADMIN: 'SYS_ADMIN',
    STAFF: 'STAFF',
    CLIENT: 'CLIENT',
    PROVIDER: 'PROVIDER'
};

export const PERMISSIONS = {
    VIEW_ALL_LINKS: 'view_all_links',
    VIEW_APPROVALS: 'view_approvals',
    VIEW_SYSTEM_MONITOR: 'view_system_monitor',
    // Add more permissions as needed
};

const rolePermissionsMap = {
    [ROLES.SYS_ADMIN]: [
        PERMISSIONS.VIEW_ALL_LINKS,
        PERMISSIONS.VIEW_APPROVALS,
        PERMISSIONS.VIEW_SYSTEM_MONITOR,
    ],
    [ROLES.STAFF]: [
        PERMISSIONS.VIEW_ALL_LINKS,
        PERMISSIONS.VIEW_APPROVALS,
    ],
    [ROLES.CLIENT]: [
        PERMISSIONS.VIEW_APPROVALS,
    ],
    [ROLES.PROVIDER]: [
        PERMISSIONS.VIEW_APPROVALS,
    ],
};

export const PermissionsProvider = ({ children }) => {
    const { currentUser } = useAuth();

    const hasPermission = (permission) => {
        if (!currentUser || !currentUser.access_level) return false;
        console.log(currentUser.access_level);
        const userPermissions = rolePermissionsMap[currentUser.access_level] || [];
        return userPermissions.includes(permission);
    };

    const value = {
        hasPermission,
        userRole: currentUser?.access_level,
    };

    return (
        <PermissionsContext.Provider value={value}>
            {children}
        </PermissionsContext.Provider>
    );
};

export const usePermissions = () => useContext(PermissionsContext); 