import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Notifications from 'components/common/Notifications/Notifications';
import { useAuth } from "contexts/AuthContext";
import React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import Tooltip from '@mui/material/Tooltip';
import { usePermissions, PERMISSIONS } from 'contexts/PermissionsContext';
import PermissionGate from 'components/common/PermissionGate';

const TestingDisclaimer = () => {
  return (
    <Tooltip
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.primary',
        p: 5,
        '& .MuiTooltip-tooltip': {
          p: 2
        }
      }}
      title={
        <Typography variant="body1" color="text.primary">
          We are still in test mode. No changes will be saved.
        </Typography>
      }
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'warning.main',
          color: 'warning.contrastText',
          py: 0.5,
          px: 2,
          borderRadius: 1,
          mx: 2,
          cursor: 'pointer'
        }}
      >
        <WarningAmberIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 'medium',
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          }}
        >
          Test Environment
        </Typography>
      </Box>
    </Tooltip>
  );
}

const Header = ({ links, isDarkMode, toggleTheme, onDropdownClick, logoClickable = true }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout, currentUser } = useAuth();
  const { hasPermission } = usePermissions();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate('/user-profile');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleSystemMonitor = async () => {
    if (currentUser.access_level === 'SYS_ADMIN') {
      navigate('/system-monitor');
    }
  };

  const filterLinksByPermission = (links) => {
    return links.filter(link => {
      // Special case for dropdown type
      if (link.type === 'dropdown') {
        const filteredItems = link.items.filter(item =>
          hasPermission(item.permission || PERMISSIONS.VIEW_ALL_LINKS)
        );
        return filteredItems.length > 0 ? { ...link, items: filteredItems } : null;
      }
      // Regular links
      return hasPermission(link.permission || PERMISSIONS.VIEW_ALL_LINKS);
    }).filter(Boolean);
  };

  const authorizedLinks = filterLinksByPermission(links);

  const renderLink = (link) => {
    return (
      <PermissionGate
        permission={link.permission || PERMISSIONS.VIEW_ALL_LINKS}
        key={link.label}
      >
        {link.type === 'dropdown' ? (
          <Button
            onClick={onDropdownClick}
            sx={{ ml: 2 }}
            color="inherit"
            endIcon={<KeyboardArrowDown />}
          >
            {link.label}
          </Button>
        ) : (
          <Button
            component={link.openInNewTab ? 'a' : RouterLink}
            to={link.to}
            href={link.openInNewTab ? link.to : undefined}
            target={link.openInNewTab ? "_blank" : undefined}
            rel={link.openInNewTab ? "noopener noreferrer" : undefined}
            sx={{ ml: 2 }}
            color="inherit"
          >
            {link.label}
          </Button>
        )}
      </PermissionGate>
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Logo clickable={logoClickable} />
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {authorizedLinks.map((link) => renderLink(link))}
        </Box>
      </Box>
      {/* <TestingDisclaimer /> */}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={toggleTheme} sx={{ ml: 2, color: 'inherit' }}>
          {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <Notifications />
        <IconButton
          onClick={handleMenu}
          sx={{ ml: 2 }}
        >
          <Avatar alt="User Name" src="/path-to-avatar.jpg" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          {currentUser && currentUser.access_level === 'SYS_ADMIN' && <MenuItem onClick={handleSystemMonitor}>System Monitor</MenuItem>}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;
