import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Grid,
    Chip,
    Card,
    CardContent,
    CardMedia,
    Button,
    Container,
    Table,
    TableBody,
    TableCell,
    TableRow,
    styled,
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { ClickableTypography } from "components/common/Style/ClickableTypography";
import { formatPercentage, formatDate } from "utils/dataUtils";

const DetDetail = () => {
    const location = useLocation();
    const accountData = location.state?.accountData;
    const det = location.state?.det;

    // when the matter is clicked navigate back to the detections page 
    const navigate = useNavigate();
    const handleAccountClick = () => {
        navigate(`/approvals/detections/${det.client_id}/${det.account_id}`, { state: { rowData: accountData } });
    };

    console.log("Detection Detail", det);
    console.log("Account Data", accountData);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Container maxWidth={false} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', py: { xs: 2, sm: 3 }, px: { xs: 2, sm: 3 } }}>
                <HeaderCard det={det} accountData={accountData} onAccountClick={handleAccountClick} />
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <VideoCard det={det} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <DetDetailsCard det={det} titleClick={null} />
                        <VideoInfoCard det={det} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};


const HeaderCard = ({ accountData, det, onAccountClick }) => (
    <Card elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs>
                <ClickableTypography variant="h5" onClick={onAccountClick}>
                    {accountData.account}
                </ClickableTypography>
            </Grid>
            <Grid item>
                <Chip label={det?.status || "Unopened"} color="primary" size="small" />
            </Grid>
        </Grid>
    </Card>
);

export const VideoCard = ({ det }) => (
    <Card elevation={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative', minHeight: { xs: '300px', sm: '400px', md: '500px' } }}>
            <CardMedia component="div" sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <VideoPlayer src={`${det?.archive_link}#t=${det?.found_at}`} />
            </CardMedia>
        </Box>
        <CardContent sx={{ p: 2, flexShrink: 0 }}>
            <VideoInfo det={det} />
            <WatchButton service={det?.service} url={det?.page_url} />
        </CardContent>
    </Card>
);

export const VideoPlayer = ({ src, poster }) => (
    <Box
        component="video"
        src={src}
        poster={poster}
        controls
        sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        }}
    />
);

const VideoInfo = ({ det }) => (
    <>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <img
                src={`http://logo.clearbit.com/${det?.service}.com`}
                alt={`${det?.service} logo`}
                style={{ width: 20, height: 20, marginRight: 8 }}
            />
            <Typography variant="subtitle1">
                {det?.video_title || `No Title`}
            </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            {det?.channel} • {formatDate(det?.date_uploaded)}
        </Typography>
    </>
);

const WatchButton = ({ service, url }) => (
    <Button
        variant="contained"
        startIcon={<PlayArrow />}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        fullWidth
    >
        Watch on {service}
    </Button>
);

const ClickableTableCell = styled(TableCell)(() => ({
    cursor: "pointer",
    color: "#1976d2",
    textDecoration: "underline",
    "&:hover": {
        textDecoration: "underline",
        color: "#1976d2",
    },
}));

const DetDetailsCard = ({ det, titleClick }) => (
    <Card elevation={3} sx={{ mb: 2, flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <Typography variant="h6" gutterBottom>
                Song Details
            </Typography>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Song Title</TableCell>
                        {det?.shazam_id ? (
                            <ClickableTableCell align="right" onClick={titleClick}>
                                {det.DetDetailsCard_title || "-"}
                            </ClickableTableCell>
                        ) : (
                            <TableCell align="right">{det.song_title || "-"}</TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>Artist</TableCell>
                        <TableCell align="right">{det?.artist || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Side</TableCell>
                        <TableCell align="right">{det?.side || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Share</TableCell>
                        <TableCell align="right">
                            {formatPercentage(det?.ownership_info || 0)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ISRC</TableCell>
                        <TableCell align="right">{det?.isrc || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ISWC</TableCell>
                        <TableCell align="right">{det?.iswc || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Work Code</TableCell>
                        <TableCell align="right">{det?.work_code || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Track ID</TableCell>
                        <TableCell align="right">{det?.track_id || "-"}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </CardContent>
    </Card>
);

const VideoInfoCard = ({ det }) => (
    <Card elevation={3} sx={{ flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <Typography variant="h6" gutterBottom>
                Video Information
            </Typography>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Channel</TableCell>
                        <TableCell align="right">{det?.channel || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell align="right">{det?.service || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Date Uploaded</TableCell>
                        <TableCell align="right">{det.date_uploaded || "-"}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </CardContent>
    </Card>
);

export default DetDetail;