import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Container, Grid, Avatar, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingSpinner from 'utils/LoadingSpinner';
import { approvalService } from 'services/api';
import { DataTable, DataTableGrouped } from "components/common/DataTable";
import { ReviewModal } from './ReviewModal';
import { ClickableTypography } from "components/common/Style/ClickableTypography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

export const TopPageGrid = ({ key1, value1, key2, value2, key3, value3, key4, value4 }) => (
    <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key1}</Typography>
            <Typography variant="body1">
                {value1 || "-"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key2}</Typography>
            <Typography variant="body1">
                {value2 || "-"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key3}</Typography>
            <Typography variant="body1">
                {value3 || "-"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">{key4}</Typography>
            <Typography variant="body1">{value4 || "-"}</Typography>
        </Grid>
    </Grid>
);

// Update ChannelsByService component
const ChannelsByService = ({ detections }) => {
    const [expandedServices, setExpandedServices] = useState({});

    // Group channels by service, excluding null channels
    const groupedChannels = detections.reduce((acc, detection) => {
        if (detection.channel) {  // Only process if channel exists
            if (!acc[detection.service]) {
                acc[detection.service] = new Set();
            }
            acc[detection.service].add(detection.channel);
        }
        return acc;
    }, {});

    const toggleService = (service) => {
        setExpandedServices(prev => ({
            ...prev,
            [service]: !prev[service]
        }));
    };

    return (
        <StyledPaper elevation={1} sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>Channels by Service</Typography>
            <Grid container spacing={2}>
                {Object.entries(groupedChannels).map(([service, channels]) => (
                    <Grid item xs={12} sm={6} md={4} key={service}>
                        <Box
                            onClick={() => toggleService(service)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': { opacity: 0.8 }
                            }}
                        >
                            {expandedServices[service] ?
                                <ExpandLessIcon color="primary" /> :
                                <ExpandMoreIcon color="primary" />
                            }
                            <Typography variant="subtitle1" color="primary">
                                {service} ({Array.from(channels).length})
                            </Typography>
                        </Box>
                        {expandedServices[service] && (
                            <Box sx={{ ml: 4, mt: 1 }}>
                                {Array.from(channels).map((channel) => (
                                    <Typography variant="body2" key={channel}>
                                        • {channel}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Grid>
                ))}
            </Grid>
        </StyledPaper>
    );
};

const ParentCompany = ({ parent }) => {
    if (!parent) return null;

    return (
        <StyledPaper elevation={1}>
            <Typography variant="subtitle2" color="text.secondary">
                Parent Company
            </Typography>
            <Typography variant="body1">
                {parent}
            </Typography>
        </StyledPaper>
    );
};

const TABLE_COLS = ['service', 'channel', 'song_title', 'artist', 'side', 'ownership_info', 'video_title', 'date_uploaded', 'reviewed', 'status', 'note', 'review_date', 'id']

const Detections = () => {
    const { clientId, accountId } = useParams();
    const location = useLocation();
    const rowData = location.state?.rowData;
    const [detections, setDetections] = useState([]);
    const [numDetections, setNumDetections] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        const fetchDetections = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await approvalService.getDetections(clientId, accountId);
                setDetections(response.data);
                setNumDetections(response.data.length);
            } catch (err) {
                console.error('Error fetching detections:', err);
                setError(err.response?.data?.detail || 'An error occurred while fetching detections');
            } finally {
                setLoading(false);
            }
        };

        if (accountId && clientId) {
            fetchDetections();
        }
    }, [accountId, clientId]);

    const handleDetectionClick = useCallback((row) => {

        // Convert both IDs to strings for comparison
        const det = detections.find(det => String(det.id) === String(row.id));
        console.log("Det", det.song_title);

        if (!det) {
            console.error("Detection not found for row:", row);
            return;
        }

        navigate(`/approvals/detection/${clientId}/${accountId}/${det.id}`, { state: { det: det, accountData: rowData } });
    }, [navigate, detections, clientId, accountId, rowData]);

    const handleAccountClick = () => {
        if (rowData.account_id) {
            navigate(`/account/${rowData.account_id}`);
        }
    };

    const handleUpdateApproval = (rows) => {
        setSelectedRows(rows);
        setModalOpen(true);
    };

    const handleSubmit = async (status, notes) => {
        try {
            const reviewData = selectedRows.map(row => ({
                id: row.id,
                client_id: parseInt(clientId),
                status: status,
                note: notes || null,
                is_individual_review: true
            }));
            await approvalService.detectionReview(reviewData);
            setModalOpen(false);
            setSelectionModel([]);
            setSelectedRows([]);

            // Refresh the detections data
            const response = await approvalService.getDetections(clientId, accountId);
            setDetections(response.data);
        } catch (error) {
            console.error('Failed to update detections:', error);
        }
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    pt: 4,
                }}
            >
                <LoadingSpinner />
            </Box>
        );
    }
    if (error) {
        return (
            <Box sx={{ height: "100%", width: "100%", pt: 4 }}>
                <Typography variant="h6" color="error">
                    Error: {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%", maxWidth: "100%" }}>
            <Container maxWidth={false}>
                <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <Avatar
                                src={rowData?.logo}
                                alt={rowData?.client || "logo"}
                                sx={{ width: 80, height: 80 }}
                            />
                        </Grid>
                        <Grid item xs>
                            <ClickableTypography
                                variant="h4"
                                gutterBottom
                                color="text.secondary"
                                onClick={handleAccountClick}
                            >
                                {rowData?.account}
                            </ClickableTypography>
                            <Typography variant="subtitle1" color="text.secondary">
                                {rowData?.client || "Client"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <TopPageGrid
                        key1="Detections"
                        value1={rowData?.detections || '0'}
                        key2="Within 3 Years"
                        value2={rowData?.within_3_years || '0'}
                        key3="Domestic"
                        value3={rowData?.domestic || '0'}
                        key4="Foreign"
                        value4={rowData?.foreign || '0'}
                    />
                </StyledPaper>

                <ParentCompany parent={rowData?.parent} />
                <ChannelsByService detections={detections} />

                <Divider sx={{ my: 3 }} />
                <Box elevation={1} sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                    {/* <Button variant="contained" onClick={() => setModalOpen(true)}>Update Approval</Button> */}
                    <ReviewModal
                        open={modalOpen}
                        handleClose={() => setModalOpen(false)}
                        id={rowData?.id}
                        client={rowData?.client}
                        account={rowData?.account}
                        handleSubmit={handleSubmit}
                    />
                </Box>
                <StyledPaper elevation={1}>
                    <Typography variant="h6" gutterBottom sx={{ pl: 1 }}>
                        Detections
                    </Typography>
                    <DataTable
                        data={detections}
                        defaultVisibleColumns={TABLE_COLS}
                        exportFilename={`detections-${accountId}-${clientId}.xlsx`}
                        onRowClick={handleDetectionClick}
                        checkboxSelection={true}
                        selectionActions={[{
                            name: "Update Approval",
                            handler: handleUpdateApproval
                        }]}
                        selectionModel={selectionModel}
                        onSelectionModelChange={setSelectionModel}
                    />
                </StyledPaper>
            </Container>
        </Box >
    );
};

export default Detections; 